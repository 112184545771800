<template>
  <v-row class="justify-center">
    <v-col cols="12" sm="12" md="6" lg="5">
      <div v-if="sessionCompleted">
        <v-alert type="success">Signature envoyée</v-alert>
      </div>
      <div v-else-if="sessionRunning">
        <v-text-field
          v-if="askEmail"
          v-model="email"
          type="email"
          label="Votre adresse email"
          outlined
          autocomplete="off"
          @input="emailEdited"
        ></v-text-field>
        <div class="text-body-1">Dessinez votre signature :</div>
        <drawer
          style="width: 100%"
          :clear.sync="clearCanvas"
          @empty="canvasEmpty = $event"
        ></drawer>
        <div class="d-flex mt-3">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="clear" class="mr-2">
            Effacer
          </v-btn>
          <v-btn color="success" @click="validate" :disabled="canvasEmpty">
            Valider
          </v-btn>
        </div>
      </div>
      <div v-else>
        <div class="text-h5 text-center">
          <v-icon color="success" large>mdi-check-circle</v-icon>
          Prêt à recevoir une demande du photographe
        </div>
      </div>
    </v-col>
    <v-dialog
      v-model="createPINDialog"
      persistent
      width="500px"
      @keydown.enter="savePIN"
    >
      <v-card>
        <v-card-title>Créer un code PIN</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-2">
          <p>
            Le mode signature est à utiliser sur un appareil distant pour
            permettre à vos clients de dessiner leur signature. <br />
            Depuis votre appareil principal, via l'Éditeur photo, lorsque vous
            cliquez sur le bouton "Sur l'appareil distant" dans la zone de
            signature, cet appareil affichera automatiquement un champ
            permettant à votre client de signer.
          </p>
          <p class="font-weight-medium">
            Choisissez le code PIN permettant de quitter le mode signature sur
            cet appareil :
          </p>
          <pincode-input
            secure
            autofocus
            v-model="pin"
            class="d-flex justify-center"
          ></pincode-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :to="{ name: 'Home' }" exact text>
            <v-icon left>mdi-chevron-left</v-icon>
            annuler
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="savePIN" text>Enregistrer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checkPINDialog"
      persistent
      width="500px"
      @keydown.enter="checkPIN"
    >
      <v-card>
        <v-card-title>Quitter le mode client</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-2">
          <p>
            Veuillez saisir votre code PIN pour quitter le mode client.
          </p>
          <pincode-input
            secure
            autofocus
            v-model="pin"
            class="d-flex justify-center"
          ></pincode-input>
          <div v-if="PINError" class="error--text">{{ PINError }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark @click="checkPINDialog = false">Annuler</v-btn>
          <v-btn color="success" @click="checkPIN">Quitter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Drawer from '@/components/Drawer'
import PincodeInput from 'vue-pincode-input'

export default {
  name: 'Sign',
  components: { Drawer, PincodeInput },
  data() {
    return {
      sessionRunning: false,
      sessionCompleted: false,
      clearCanvas: false,
      askEmail: true,
      email: '',
      createPINDialog: false,
      checkPINDialog: false,
      pin: '',
      next: null,
      PINError: null,
      canvasEmpty: true,
    }
  },
  methods: {
    async init() {
      const postId = this.$root.get('currentLicenceId')
      await this.$store.dispatch('socket/connect', postId)

      await this.$store.dispatch('socket/on', {
        event: 'need_signature',
        callback: data => {
          this.clear()
          this.sessionRunning = true
          this.email = ''
          this.askEmail = data.askEmail
        },
      })
      await this.$store.dispatch('socket/on', {
        event: 'cancel_signature',
        callback: () => {
          this.clear()
          this.sessionRunning = false
        },
      })
      await this.$store.dispatch('socket/on', {
        event: 'ask_email',
        callback: data => {
          this.askEmail = data
        },
      })
      await this.$store.dispatch('socket/on', {
        event: 'set_email',
        callback: data => {
          this.email = data
        },
      })
    },
    validate() {
      this.$store.dispatch('socket/emit', {
        event: 'validate_signature',
        data: { email: this.email },
      })
      this.sessionCompleted = true
      this.sessionRunning = false
      setTimeout(() => (this.sessionCompleted = false), 5000)
    },
    clear() {
      this.clearCanvas = true
      this.$store.dispatch('socket/emit', {
        event: 'reset_signature',
      })
      this.$nextTick(() => (this.clearCanvas = false))
    },
    emailEdited() {
      this.$store.dispatch('socket/emit', {
        event: 'set_email',
        data: this.email,
      })
    },
    savePIN() {
      localStorage.setItem('pin', this.pin)
      this.pin = ''
      this.createPINDialog = false
      location.reload()
    },
    checkPIN() {
      const pin = localStorage.getItem('pin')
      if (pin === this.pin) {
        console.log('ok')
        this.next()
      } else {
        console.log('NO')
        this.PINError = 'Code PIN incorrect'
      }
    },
  },
  mounted() {
    this.init()
    const pin = localStorage.getItem('pin')
    if (pin === null) {
      this.createPINDialog = true
    }
  },
  beforeRouteLeave(to, from, next) {
    this.next = next
    const pin = localStorage.getItem('pin')
    if (pin === null) {
      next()
    } else {
      this.checkPINDialog = true
    }
  },
}
</script>

<style scoped lang="scss">
#draw-canvas {
  border: 1px solid black;
}

::v-deep {
  input.vue-pincode-input {
    max-width: 70px;
    font-size: 2.1rem;
  }
}
</style>
